import React from "react"

import Layout from "@/components/Layout"
import SEO from "@/components/SEO"
import { StaticImage } from "gatsby-plugin-image"
import Feature from "@/components/Feature"
import BackgroundSection from "@/components/BackgroundSection"
import Quote from "@/components/Quote"

type PageProps = {
  data: any
}

const CentreOfInnovation: React.FC<PageProps> = ({}) => (
  <Layout
    sectionOne={
      <>
        <StaticImage
          src="../../static/images/2018/03/innovation.jpg"
          className="absolute inset-0 -z-[1]"
          alt="Centre of Innovation"
        />
        <div className="container text-white pt-96 pb-4 flex flex-col items-center text-center">
          <h1 className="pb-2 md:pb-8 responsive-heading uppercase">
            THE CENTRE OF <span className="text-primary">INNOVATION</span>
          </h1>
          <div className="pt-8 flex justify-center">
            <img src="/images/down.png" alt="Down arrow" />
          </div>
        </div>
      </>
    }
  >
    <SEO title="Centre of Innovation" />
    <div className="container grid gap-8 lg:gap-x-4 md:grid-cols-2 py-24">
      <div>
        <h2 className="uppercase text-[36px] sm:text-[46px] font-medium leading-[0.9] pb-4">
          Pupil numbers in <span className="text-primary">technology</span>{" "}
          based subjects have{" "}
          <span className="text-primary">grown significantly</span> over the
          past five years
        </h2>
      </div>

      <div className="relative min-h-[300px] max-h-[360px] lg:pl-4">
        <img
          className="w-full h-full object-cover"
          src="/images/2018/03/science-innov.jpg"
          alt="Centre of Innovation"
        />
      </div>
      <div>
        <p className="pb-4">
          More than half of all pupils now study technology, manufacturing or
          engineering, and this trend is expected to continue.
        </p>
        <p>
          Alongside GCSE & A level Technology & Design, RBAI has introduced a
          range of subjects including GCSE Manufacturing, Motor Vehicle studies,
          and B TEC Engineering.
        </p>
      </div>
      <div className="lg:pl-4">
        <p>
          A significant redevelopment of the old Technology department is
          underway, doubling the size of our facilities, providing 4 large,
          fully equipped teaching rooms, complemented by extensive project
          planning areas for advanced design and creative learning.
        </p>
      </div>
    </div>

    <div className="container py-24">
      <div className="flex flex-col gap-4 justify-between">
        <h2 className="responsive-heading">
          IMPACTING
          <br />
          <span className="text-primary">PUPIL PERFORMANCE</span>{" "}
        </h2>
        <p>
          The creation of a new Centre of Innovation will benefit our pupils by:
        </p>
      </div>
      <div className="grid gap-y-8 md:grid-cols-3 pt-20">
        <Feature
          description="Enabling pupils to develop more ambitious coursework projects
                and facilitating greater pupil collaboration."
        />
        <Feature
          description="Providing capacity for continued growth as the technology,
                manufacturing and engineering curriculum evolves in the longer
                term."
        />
        <Feature description="Equipping pupils for future employment opportunities." />
      </div>
    </div>

    <BackgroundSection
      heading={
        <h2>
          EXAM <br />
          <span className="text-primary">SUCCESS</span>
        </h2>
      }
      paragraphs={
        <>
          <p>
            <strong>2016</strong> - Carson McKee achieved four A* at A level,
            including scoring 100% in his Technology coursework, enabling him to
            read engineering at St Andrews University, Scotland.
          </p>
          <p>
            <strong>2015</strong> - Thomas Donaldson achieved three A* and one A
            at A level, and is now studying Chemical Engineering at Imperial
            College, London. Thomas represented Northern Ireland in the
            International Science (Chemistry) Olympiad in Azerbaijan.
          </p>
          <p>
            RBAI have had at least one pupil in the top 3 for GCSE Manufacturing
            in Northern Ireland for the past 4 years.
          </p>
        </>
      }
      paragraphsClassName="max-w-[400px]"
      bg="/images/2018/03/innov-3.jpg"
    />

    <div className="container grid md:grid-cols-2 pt-14 px-4">
      <Quote
        quote="The quality of teaching and confidence I gained at RBAI have been
        vital to my career progression. The School’s investment in science
        and technology will make a very tangible difference to pupils
        wishing to pursue careers in these areas."
        author={
          <span className="leading-[30px] py-2">
            Paul Chambers (RBAI 2004-11)
            <br />
            MEng Civil Engineering from Durham University, achieved the
            Institution of Civil Engineers (ICE) Emerging Engineers Award in
            2016. He works for Skanska UK, a leading international project
            development and construction company.
          </span>
        }
        className="bg-[#111111]"
      />

      <Quote
        quote="Hardly a day goes by when I don’t draw upon STEAM elements learnt
        at school. As an employer, we seek young professionals with the
        solid STEAM grounding and the creative skills fostered by Inst."
        author={
          <span className="leading-[30px] py-2">
            Ian Harper (RBAI 1990-97)
            <br />
            1st Class Honours in Computer Science, University of Warwick,
            established Future Games of London. His Hungry Shark game series has
            been downloaded more than 500m times worldwide. Games Industry.biz
            identified Ian as the most influential person in the UK mobile
            gaming industry in 2017.
          </span>
        }
        className="bg-[#faba20]"
      />
    </div>
  </Layout>
)

export default CentreOfInnovation
